<template>
  <div class="tab-content">
    <div class="title-wrap">
      <h2 class="title">
        {{cnt.title}}
        <span v-if="cnt.subTitle !== '' && cnt.subTitle !== null">【{{cnt.subTitle}}代下载】</span>
      </h2>
      <div class="fr" v-if="cnt.titleState == 'success'">
        <el-button type="success" icon="el-icon-check" circle></el-button>正常
      </div>
      <div class="fr" v-if="cnt.titleState == 'error'">
        <el-button type="danger" icon="el-icon-close" circle></el-button>异常
      </div>
    </div>
    <el-input
      type="textarea"
      v-model="cnt.label"
      @input="descInput"
      :placeholder="'请将' + cnt.title + placeholder"
      class="isTextarea"
    ></el-input>
    <p class="error">{{errorMsg}}</p>
    <el-button class="btn-border" type="primary" @click="search" plain icon="el-icon-search" :disabled="isSearching">搜索</el-button>
  </div>
</template>

<script>
  import { message } from '@/utils/resetMessage';
  export default {
    props: {
      // 带有默认值的对象
      tabContent: {
        type: Object,
        default() {
          return {
            title: "",
            subTitle: "",
            label: ""
          };
        }
      },
      intimes:{
        type:Number
      },
      isSearching:{
        type:Boolean
      }
    },
    data() {
      return {
        placeholder: "图片ID编号粘贴到本框提交，一个ID编号一行，多个ID号请回车另起一行输入，没有被加载说明图片不存在或无法下载…",
        errorMsg: "",
        form: {
          imageId: '',
          webType: ''
        }
      };
    },
    computed: {
      cnt() {
        return this.tabContent;
      }
    },
    mounted() {},
    methods: {
      search() {
        if(this.intimes <=0){
          message.error({
            message: '下载次数为0，不能搜索',
            duration: 4000,
            offset: 80
          });
          return ;
        }
        if (this.cnt.label !== "" && this.cnt.label !== null ) {
          if(this.cnt.label.split(/\n/).length > 500){
            message.error({
              message: 'The number of ID lines is limited to 500',
              duration: 4000,
              offset: 80
            });
            return ;
          }
          this.errorMsg = "";
          var recArray = this.cnt.label.split("\n");
          //搜索过滤回车
          var recNewArray = [];
          for(var i = 0;i<recArray.length;i++){
            if(recArray[i].length > 3){
              var pattern = /[0-9]\d{4,12}/g;
              var matchResult = recArray[i].replace(/ /g,'').match(pattern);
              try{
                if(matchResult.length>0){
                  var rec = '';
                  matchResult.forEach(item=>{
                    rec += item;
                  })
                  if(rec != null && rec != '' && typeof(rec) != "undefined") {
                    if(recNewArray.indexOf(rec) < 0){
                      recNewArray.push(rec);
                    }
                  }
                }
              }catch (e) {
                this.errorMsg = "存在ID无效";
              }
            }
          }
          //判断是否需要发送事件
          if(recNewArray.length>0){
            this.$emit("search", recNewArray);
          }else{
            this.errorMsg = "ID cannot be empty";
          }
        } else {
          this.errorMsg = "ID cannot be empty";
        }
      },
      descInput(){
        this.txtVal = this.cnt.label;
        if(this.txtVal.split(/\n/).length > 100) {
          message.error({
            message: 'ID行数限制100个',
            duration: 4000,
            offset: 80
          });
          return ;
        }
      }
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tab-content {
  .title-wrap {
    margin-bottom: 15px;
    position: relative;
    .title {
      display: inline-block;
      font-size: 32px;
      color: #000;
      font-weight: normal;
      span {
        font-size: 18px;
        color: #bbc1c7;
      }
    }
    .fr {
      float: right;
      color: #44d995;
      .iconfont {
        font-size: 14px;
      }
    }
    .el-button.is-circle {
      margin-right: 10px;
      padding: 0;
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 50%;
    }
  }
  .btn-border.el-button {
    margin-bottom: 15px;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    color: #0773fc;
    background: #fff;
    border-color: #0773fc;
    outline: none;
    i {
        color: #0773fc;
    }
  }
  .el-button--primary.is-plain:hover {
    background: #0773fc;
    border-color: #0773fc;
    color: #ffffff;
  }
  .error {
    margin-bottom: 10px;
    color: #f56c6c;
  }
}
</style>